<template>
	<div class="mask-page">
		<div class="main" :style="mainBg">
			<img class="tips-img" src="@/assets/images/blindbox/title_04.png" alt="">
			<p class="tips">恭喜获得以下奖励：</p>
			<div class="img-box">
				<img :src="'http://qn.qs520.mobi/'+prizeUrl" alt="">
			</div>
			<div @click="goBack()" class="btn-box">
				<img src="@/assets/images/blindbox/btn_03.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				mainBg: {
					backgroundImage: `url(${require("@/assets/images/blindbox/result-bj.png")})`,
					backgroundSize: '100%, 100%'
				},
				prizeUrl: ''
			}
		},
		methods: {
			goBack() {
				this.$router.back()
			}
		},
		mounted() {
			this.prizeUrl = this.$route.query.prizeUrl
		}
	}
</script>

<style lang="stylus" scoped>
.mask-page
	position absolute
	top 0
	left 0
	display flex
	flex-direction column
	justify-content center
	align-items center
	padding 0 0.746666rem
	box-sizing border-box
	width 100%
	height 100%
	.main
		position relative
		width 100%
		height 10.666666rem
		.tips-img 
			position absolute
			left -0.533333rem
			top 2px
			width 6.56rem
			height 1.74rem
		.tips
			margin 2.666666rem 0 0.906666rem 0.8rem
			font-size 0.426666rem
			color #5A4217
			font-weight 400
			text-align left
		.img-box
			box-sizing border-box
			padding 0.133333rem
			margin auto
			width 3.2rem
			height 3.2rem
			border 1px solid #B5A585
			background #E3DAC4
			img
				width 100%
				height 100%
		.btn-box 
			position absolute
			bottom 0.5rem
			left 50%
			transform translate(-50%, 0)
			font-size 0
			img 
				width 5.333333rem
				height 1.546666rem
</style>
